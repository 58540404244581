import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _66eaf1c0 = () => interopDefault(import('../pages/area-restrita/index.vue' /* webpackChunkName: "pages/area-restrita/index" */))
const _cb361cec = () => interopDefault(import('../pages/base-de-conhecimento/index.vue' /* webpackChunkName: "pages/base-de-conhecimento/index" */))
const _763ba986 = () => interopDefault(import('../pages/busca/index.vue' /* webpackChunkName: "pages/busca/index" */))
const _55ed2692 = () => interopDefault(import('../pages/contato/index.vue' /* webpackChunkName: "pages/contato/index" */))
const _79bcf592 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e3bff108 = () => interopDefault(import('../pages/institucional/index.vue' /* webpackChunkName: "pages/institucional/index" */))
const _6b8e7d7c = () => interopDefault(import('../pages/noticias/index.vue' /* webpackChunkName: "pages/noticias/index" */))
const _f68d2dfe = () => interopDefault(import('../pages/politicas-de-privacidade/index.vue' /* webpackChunkName: "pages/politicas-de-privacidade/index" */))
const _4a7df308 = () => interopDefault(import('../pages/produtos/index.vue' /* webpackChunkName: "pages/produtos/index" */))
const _21fab0ad = () => interopDefault(import('../pages/solucoes/index.vue' /* webpackChunkName: "pages/solucoes/index" */))
const _67180c59 = () => interopDefault(import('../pages/styleguide/index.vue' /* webpackChunkName: "pages/styleguide/index" */))
const _0ce5540a = () => interopDefault(import('../pages/termos-de-uso/index.vue' /* webpackChunkName: "pages/termos-de-uso/index" */))
const _6f50ccfa = () => interopDefault(import('../pages/trabalhe-conosco/index.vue' /* webpackChunkName: "pages/trabalhe-conosco/index" */))
const _6e78fb40 = () => interopDefault(import('../pages/area-restrita/alterar-senha/index.vue' /* webpackChunkName: "pages/area-restrita/alterar-senha/index" */))
const _51bd981e = () => interopDefault(import('../pages/area-restrita/cadastro/index.vue' /* webpackChunkName: "pages/area-restrita/cadastro/index" */))
const _3a616610 = () => interopDefault(import('../pages/area-restrita/esqueci-minha-senha/index.vue' /* webpackChunkName: "pages/area-restrita/esqueci-minha-senha/index" */))
const _f7d9caa2 = () => interopDefault(import('../pages/area-restrita/logout/index.vue' /* webpackChunkName: "pages/area-restrita/logout/index" */))
const _9c7e1e0e = () => interopDefault(import('../pages/area-restrita/meus-dados/index.vue' /* webpackChunkName: "pages/area-restrita/meus-dados/index" */))
const _d5f053de = () => interopDefault(import('../pages/area-restrita/secoes/index.vue' /* webpackChunkName: "pages/area-restrita/secoes/index" */))
const _71622792 = () => interopDefault(import('../pages/area-restrita/secoes/_section.vue' /* webpackChunkName: "pages/area-restrita/secoes/_section" */))
const _2d98ea21 = () => interopDefault(import('../pages/base-de-conhecimento/_section/index.vue' /* webpackChunkName: "pages/base-de-conhecimento/_section/index" */))
const _97571c6c = () => interopDefault(import('../pages/noticias/_category/index.vue' /* webpackChunkName: "pages/noticias/_category/index" */))
const _31712f54 = () => interopDefault(import('../pages/produtos/_category/index.vue' /* webpackChunkName: "pages/produtos/_category/index" */))
const _0afb8e76 = () => interopDefault(import('../pages/solucoes/_solution/index.vue' /* webpackChunkName: "pages/solucoes/_solution/index" */))
const _1f27be24 = () => interopDefault(import('../pages/base-de-conhecimento/_section/_publication.vue' /* webpackChunkName: "pages/base-de-conhecimento/_section/_publication" */))
const _2fe1ed3a = () => interopDefault(import('../pages/noticias/_category/_post/index.vue' /* webpackChunkName: "pages/noticias/_category/_post/index" */))
const _e9d6b124 = () => interopDefault(import('../pages/produtos/_category/_subcategory/index.vue' /* webpackChunkName: "pages/produtos/_category/_subcategory/index" */))
const _8571d7c6 = () => interopDefault(import('../pages/solucoes/_solution/_application.vue' /* webpackChunkName: "pages/solucoes/_solution/_application" */))
const _0b9d4372 = () => interopDefault(import('../pages/produtos/_category/_subcategory/_product/index.vue' /* webpackChunkName: "pages/produtos/_category/_subcategory/_product/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/area-restrita",
    component: _66eaf1c0,
    name: "area-restrita___br"
  }, {
    path: "/base-de-conhecimento",
    component: _cb361cec,
    name: "base-de-conhecimento___br"
  }, {
    path: "/busca",
    component: _763ba986,
    name: "busca___br"
  }, {
    path: "/contato",
    component: _55ed2692,
    name: "contato___br"
  }, {
    path: "/en",
    component: _79bcf592,
    name: "index___en"
  }, {
    path: "/es",
    component: _79bcf592,
    name: "index___es"
  }, {
    path: "/institucional",
    component: _e3bff108,
    name: "institucional___br"
  }, {
    path: "/noticias",
    component: _6b8e7d7c,
    name: "noticias___br"
  }, {
    path: "/politicas-de-privacidade",
    component: _f68d2dfe,
    name: "politicas-de-privacidade___br"
  }, {
    path: "/produtos",
    component: _4a7df308,
    name: "produtos___br"
  }, {
    path: "/solucoes",
    component: _21fab0ad,
    name: "solucoes___br"
  }, {
    path: "/styleguide",
    component: _67180c59,
    name: "styleguide___br"
  }, {
    path: "/termos-de-uso",
    component: _0ce5540a,
    name: "termos-de-uso___br"
  }, {
    path: "/trabalhe-conosco",
    component: _6f50ccfa,
    name: "trabalhe-conosco___br"
  }, {
    path: "/area-restrita/alterar-senha",
    component: _6e78fb40,
    name: "area-restrita-alterar-senha___br"
  }, {
    path: "/area-restrita/cadastro",
    component: _51bd981e,
    name: "area-restrita-cadastro___br"
  }, {
    path: "/area-restrita/esqueci-minha-senha",
    component: _3a616610,
    name: "area-restrita-esqueci-minha-senha___br"
  }, {
    path: "/area-restrita/logout",
    component: _f7d9caa2,
    name: "area-restrita-logout___br"
  }, {
    path: "/area-restrita/meus-dados",
    component: _9c7e1e0e,
    name: "area-restrita-meus-dados___br"
  }, {
    path: "/area-restrita/secoes",
    component: _d5f053de,
    name: "area-restrita-secoes___br"
  }, {
    path: "/en/about-us",
    component: _e3bff108,
    name: "institucional___en"
  }, {
    path: "/en/contact",
    component: _55ed2692,
    name: "contato___en"
  }, {
    path: "/en/knowledge-base",
    component: _cb361cec,
    name: "base-de-conhecimento___en"
  }, {
    path: "/en/news",
    component: _6b8e7d7c,
    name: "noticias___en"
  }, {
    path: "/en/privacy-policies",
    component: _f68d2dfe,
    name: "politicas-de-privacidade___en"
  }, {
    path: "/en/products",
    component: _4a7df308,
    name: "produtos___en"
  }, {
    path: "/en/restricted-area",
    component: _66eaf1c0,
    name: "area-restrita___en"
  }, {
    path: "/en/search",
    component: _763ba986,
    name: "busca___en"
  }, {
    path: "/en/solutions",
    component: _21fab0ad,
    name: "solucoes___en"
  }, {
    path: "/en/styleguide",
    component: _67180c59,
    name: "styleguide___en"
  }, {
    path: "/en/terms-of-use",
    component: _0ce5540a,
    name: "termos-de-uso___en"
  }, {
    path: "/en/trabalhe-conosco",
    component: _6f50ccfa,
    name: "trabalhe-conosco___en"
  }, {
    path: "/es/area-restringida",
    component: _66eaf1c0,
    name: "area-restrita___es"
  }, {
    path: "/es/base-de-conocimientos",
    component: _cb361cec,
    name: "base-de-conhecimento___es"
  }, {
    path: "/es/buscar",
    component: _763ba986,
    name: "busca___es"
  }, {
    path: "/es/contacto",
    component: _55ed2692,
    name: "contato___es"
  }, {
    path: "/es/institucional",
    component: _e3bff108,
    name: "institucional___es"
  }, {
    path: "/es/noticias",
    component: _6b8e7d7c,
    name: "noticias___es"
  }, {
    path: "/es/politicas-de-privacidad",
    component: _f68d2dfe,
    name: "politicas-de-privacidade___es"
  }, {
    path: "/es/productos",
    component: _4a7df308,
    name: "produtos___es"
  }, {
    path: "/es/soluciones",
    component: _21fab0ad,
    name: "solucoes___es"
  }, {
    path: "/es/styleguide",
    component: _67180c59,
    name: "styleguide___es"
  }, {
    path: "/es/terminos-de-uso",
    component: _0ce5540a,
    name: "termos-de-uso___es"
  }, {
    path: "/es/trabalhe-conosco",
    component: _6f50ccfa,
    name: "trabalhe-conosco___es"
  }, {
    path: "/en/restricted-area/forgot-my-password",
    component: _3a616610,
    name: "area-restrita-esqueci-minha-senha___en"
  }, {
    path: "/en/restricted-area/logout",
    component: _f7d9caa2,
    name: "area-restrita-logout___en"
  }, {
    path: "/en/restricted-area/my-data",
    component: _9c7e1e0e,
    name: "area-restrita-meus-dados___en"
  }, {
    path: "/en/restricted-area/register",
    component: _51bd981e,
    name: "area-restrita-cadastro___en"
  }, {
    path: "/en/restricted-area/sections",
    component: _d5f053de,
    name: "area-restrita-secoes___en"
  }, {
    path: "/en/restricted-area/update-password",
    component: _6e78fb40,
    name: "area-restrita-alterar-senha___en"
  }, {
    path: "/es/area-restringida/cambiar-contrasena",
    component: _6e78fb40,
    name: "area-restrita-alterar-senha___es"
  }, {
    path: "/es/area-restringida/logout",
    component: _f7d9caa2,
    name: "area-restrita-logout___es"
  }, {
    path: "/es/area-restringida/mis-datos",
    component: _9c7e1e0e,
    name: "area-restrita-meus-dados___es"
  }, {
    path: "/es/area-restringida/olvide-mi-contrasena",
    component: _3a616610,
    name: "area-restrita-esqueci-minha-senha___es"
  }, {
    path: "/es/area-restringida/registro",
    component: _51bd981e,
    name: "area-restrita-cadastro___es"
  }, {
    path: "/es/area-restringida/secciones",
    component: _d5f053de,
    name: "area-restrita-secoes___es"
  }, {
    path: "/en/restricted-area/sections/:section",
    component: _71622792,
    name: "area-restrita-secoes-section___en"
  }, {
    path: "/es/area-restringida/secciones/:section",
    component: _71622792,
    name: "area-restrita-secoes-section___es"
  }, {
    path: "/area-restrita/secoes/:section",
    component: _71622792,
    name: "area-restrita-secoes-section___br"
  }, {
    path: "/en/knowledge-base/:section",
    component: _2d98ea21,
    name: "base-de-conhecimento-section___en"
  }, {
    path: "/en/news/:category",
    component: _97571c6c,
    name: "noticias-category___en"
  }, {
    path: "/en/products/:category",
    component: _31712f54,
    name: "produtos-category___en"
  }, {
    path: "/en/solutions/:solution",
    component: _0afb8e76,
    name: "solucoes-solution___en"
  }, {
    path: "/es/base-de-conocimientos/:section",
    component: _2d98ea21,
    name: "base-de-conhecimento-section___es"
  }, {
    path: "/es/noticias/:category",
    component: _97571c6c,
    name: "noticias-category___es"
  }, {
    path: "/es/productos/:category",
    component: _31712f54,
    name: "produtos-category___es"
  }, {
    path: "/es/soluciones/:solution",
    component: _0afb8e76,
    name: "solucoes-solution___es"
  }, {
    path: "/en/knowledge-base/:section/:publication",
    component: _1f27be24,
    name: "base-de-conhecimento-section-publication___en"
  }, {
    path: "/en/news/:category/:post",
    component: _2fe1ed3a,
    name: "noticias-category-post___en"
  }, {
    path: "/en/products/:category/:subcategory",
    component: _e9d6b124,
    name: "produtos-category-subcategory___en"
  }, {
    path: "/en/solutions/:solution/:application",
    component: _8571d7c6,
    name: "solucoes-solution-application___en"
  }, {
    path: "/es/base-de-conocimientos/:section/:publication",
    component: _1f27be24,
    name: "base-de-conhecimento-section-publication___es"
  }, {
    path: "/es/noticias/:category/:post",
    component: _2fe1ed3a,
    name: "noticias-category-post___es"
  }, {
    path: "/es/productos/:category/:subcategory",
    component: _e9d6b124,
    name: "produtos-category-subcategory___es"
  }, {
    path: "/es/soluciones/:solution/:application",
    component: _8571d7c6,
    name: "solucoes-solution-application___es"
  }, {
    path: "/en/products/:category/:subcategory/:product",
    component: _0b9d4372,
    name: "produtos-category-subcategory-product___en"
  }, {
    path: "/es/productos/:category/:subcategory/:product",
    component: _0b9d4372,
    name: "produtos-category-subcategory-product___es"
  }, {
    path: "/base-de-conhecimento/:section",
    component: _2d98ea21,
    name: "base-de-conhecimento-section___br"
  }, {
    path: "/noticias/:category",
    component: _97571c6c,
    name: "noticias-category___br"
  }, {
    path: "/produtos/:category",
    component: _31712f54,
    name: "produtos-category___br"
  }, {
    path: "/solucoes/:solution",
    component: _0afb8e76,
    name: "solucoes-solution___br"
  }, {
    path: "/base-de-conhecimento/:section/:publication",
    component: _1f27be24,
    name: "base-de-conhecimento-section-publication___br"
  }, {
    path: "/noticias/:category/:post",
    component: _2fe1ed3a,
    name: "noticias-category-post___br"
  }, {
    path: "/produtos/:category/:subcategory",
    component: _e9d6b124,
    name: "produtos-category-subcategory___br"
  }, {
    path: "/solucoes/:solution/:application",
    component: _8571d7c6,
    name: "solucoes-solution-application___br"
  }, {
    path: "/produtos/:category/:subcategory/:product",
    component: _0b9d4372,
    name: "produtos-category-subcategory-product___br"
  }, {
    path: "/",
    component: _79bcf592,
    name: "index___br"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
