import { render, staticRenderFns } from "./clean.vue?vue&type=template&id=4f6ef72c&"
import script from "./clean.vue?vue&type=script&lang=js&"
export * from "./clean.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseUiModal: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/ui/Modal.vue').default,BaseAlertsCookies: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/alerts/Cookies.vue').default})
