import { render, staticRenderFns } from "./system.vue?vue&type=template&id=26f842a4&"
import script from "./system.vue?vue&type=script&lang=js&"
export * from "./system.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseAlertsEnvironment: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/alerts/Environment.vue').default,BaseUiModal: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/ui/Modal.vue').default,BaseLayoutHeaderSystem: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/layout/HeaderSystem.vue').default,BaseLayoutFooter: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/layout/Footer.vue').default,BaseAlertsCookies: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/alerts/Cookies.vue').default})
