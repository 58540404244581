import { render, staticRenderFns } from "./error.vue?vue&type=template&id=518f19e9&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=518f19e9&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518f19e9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ErrorPage404: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/error/Page404.vue').default,ErrorPage500: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/error/Page500.vue').default,ErrorPage502: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/error/Page502.vue').default})
